import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { CartPage, CartBody } from './cart.styles';
import {
  authenticateUser,
  saveCart,
  placeOrder,
  setCartValidationErrorMsgModalVisibility,
  setCart,
  validateCart,
  setLogout,
} from '../../redux/actions';
import { getAuthToken } from '@/utils/getAuthToken';
import { addDeliveryInfoInCart } from '@/utils/addDeliveryInfoInCart';
import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { getStringifyAddress } from '@/utils/getStringifyAddress';
import { getRoute, PATH } from '@/utils/routes';
import { toastNotifyError } from '@/components/Common/Toast';
import CartValidationErrorMsgModal from './Components/CartValidationErrorMsgModal';
import NotAcceptingOrderPopUp from './Components/NotAcceptingOrderPopUp';
import PaymentModal from '../../components/PaymentModal';
import DesktopPaymentModal from '../../components/DesktopPaymentModal';
import AuthModal from '@/components/DesignSystem/AuthPopUp';
import OrderConfirmLoader from '@/components/DesignSystem/OrderConfirmLoader';
import useSetCart from '@/utils/customHooks';
import { ORDER_TYPE } from '@/utils/constants';
import { getLeadData } from '@/utils/localStorageFunctions';
import CheckoutAdditionalInfo from './Components/CartAdditionalInfo/CartAdditionalInfo';
import useIsComponentMounted from '@/utils/useIsComponentMounted';
import { deviceWidth } from '@/utils/deviceWidth';
import { useSSRSelector } from '@/redux/ssrStore';
import { ProductCustomizationPopUp } from '@/components/ProductCustomizationPopUp';
import EmptyCartView from './Components/EmptyCartView';
import CartItemsAndCheckout, { CartSummery } from './Components/CartItemsAndCheckout';
import PageHeader from './Components/PageHeader';
import useCartPageLandingEvent from './Hooks/useCartPageLandingEvent';
import ModifiedCartNotificationBar from './Components/ModifiedCartNotificationBar';
import useTriggerCartPixelEvent from './Hooks/useTriggerPixelEvent';
import CartItemDeletionAlert from 'src/components/CartItemDeletionAlert';
import QuntityUnavailableAlert from 'src/components/QuntityUnavailableAlert';
import { IS_MOBILE } from 'src/utils/checkClientDeviceType';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';
import { CartContext } from 'src/context/CartContext';
const STORE_CLOSE_FLAG = 0;
const DELIVERY_UNAVILABLE_FLAG = 0;
const PICKUP_UNAVILABLE_FLAG = 0;

const CART_ORDER_TYPE_TEXT = {
  SERVICE: 'service',
  DELIVERY: 'deliver',
  PICKUP: 'pickup',
};

function getOrderTypeTextForCart(orderType) {
  switch (orderType) {
    case ORDER_TYPE.SERVICE:
      return CART_ORDER_TYPE_TEXT.SERVICE;
    case ORDER_TYPE.DELIVERY:
      return CART_ORDER_TYPE_TEXT.DELIVERY;
    default:
      CART_ORDER_TYPE_TEXT.PICKUP;
  }
}

// export const CartContext = createContext({});

const PreCartPage = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { triggerSetCart } = useSetCart();
  const [state, setState] = useState({
    showAuthModel: false,
    showOrderInstructionModal: false,
    orderInstructionText: '',
    showPaymentModal: true,
    isPhoneNumber: false,
  });

  const [orderConfirmLoaderToggle, setOrderConfirmLoaderToggle] = useState(false);
  const [orderButtonClickCount, setOrderButtonClickCount] = useState(0);
  const [showAddressError, setShowAddressError] = useState(false);
  const [deletionAlertState, setDeletionAlertState] = useState({
    show: false,
    callback: null,
  });

  const [showSummeryMobile, setShowSummeryMobile] = useState(false);

  const { storeInfo, personalizedProduct } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
    personalizedProduct: state.catalogReducer?.personalizedProduct,
  }));

  const { cart, list, userData, setCartLoader, cartValidationData } = useSelector(
    (state) => ({
      cart: state.cartReducer,
      list: state.commonReducer.createList,
      userData: state.userReducer,
      setCartLoader: state.commonReducer.setCartLoader,
      cartValidationData: state.errorReducer.cart.cartValidationData,
    })
  );

  const isComponentMounted = useIsComponentMounted();

  useCartPageLandingEvent();
  useTriggerCartPixelEvent(orderButtonClickCount);

  useEffect(() => {
    if (localStorage?.getItem('auth_token')) {
      const authData = {
        auth_token: localStorage?.getItem('auth_token'),
        service: '',
      };
      dispatch(authenticateUser(authData));
      setCartAction();
    } else {
      dispatch(setLogout());
    }
  }, [userData?.isLogin, userData?.savedAddress]);

  useEffect(() => {
    if (storeInfo?.services?.store_flag === STORE_CLOSE_FLAG) {
      router.push(getRoute(PATH.PRODUCT, storeInfo?.store_info?.domain));
    }
  }, [storeInfo?.services?.store_flag]);

  useEffect(() => {
    if (!setCartLoader) setCartAction();
  }, [cart.pay_amount, cart.amount]);

  const allItems = [...cart.items, ...list];

  const setCartAction = () => {
    const cartWithDeliveryInfo = !(
      isDeliveryPincodeBased() && !userData?.savedAddress?.pincode
    )
      ? addDeliveryInfoInCart(cart, getStringifyAddress())
      : {};
    triggerSetCart({
      ...cart,
      ...cartWithDeliveryInfo,
      user_phone: userData?.data?.phone,
      phone: userData?.data?.phone,
    });
  };

  function handleCloseButton() {
    setState({
      ...state,
      showAuthModel: false,
    });
  }

  const toggleAuthModal = () => {
    const phone = getLeadData();
    const phoneReg = /(6|7|8|9)\d{9}/;
    if (allItems?.length && phone && phone.length === 10 && phone.match(phoneReg)) {
      setState((state) => ({
        ...state,
        showAuthModel: !state.showAuthModel,
        isPhoneNumber: true,
      }));
    } else {
      setState((state) => ({ ...state, showAuthModel: !state.showAuthModel }));
    }
  };

  const onDeliverHereBtnHandle = () => {
    if (
      storeInfo?.services?.payment_method === 0 &&
      getAuthToken() &&
      !orderConfirmLoaderToggle
    ) {
      setOrderConfirmLoaderToggle(!orderConfirmLoaderToggle);
    } else {
      setAddressDetails();
    }
  };

  const submitCart = () => {
    const type = getOrderTypeTextForCart(cart.order_type);

    let isFormValid = true;
    let atleastOneProductPresent = false;
    let errorMessage = 'Please create your product list';

    localStorage?.setItem('deliveryType', type);

    allItems?.map(function (el) {
      if (el.item_name && el.quantity === '') {
        isFormValid = false;
        errorMessage = 'Please enter the product quantity.';
      } else if (el.quantity === 0) {
        isFormValid = false;
        errorMessage = 'Please enter the product quantity greater than 0.';
      }
      if (el.item_name) {
        atleastOneProductPresent = true;
      }
      return;
    });

    if (isFormValid && atleastOneProductPresent) {
      if (
        storeInfo?.services?.delivery_flag === DELIVERY_UNAVILABLE_FLAG &&
        type === CART_ORDER_TYPE_TEXT.DELIVERY
      ) {
        toastNotifyError('Sorry! Delivery not available currently.');
        return;
      }
      if (localStorage?.getItem('auth_token')) {
        if (
          type === CART_ORDER_TYPE_TEXT.PICKUP ||
          type === CART_ORDER_TYPE_TEXT.SERVICE
        ) {
          dispatch(placeOrder(cart, router));
        } else {
          dispatch(
            saveCart({
              ...cart,
            })
          );
          onDeliverHereBtnHandle();
        }
      } else {
        dispatch(
          saveCart({
            ...cart,
            items: allItems,
            instructions: state.orderInstructionText,
          })
        );
        toggleAuthModal();
      }
    } else {
      toastNotifyError(errorMessage);
    }
  };

  function setAddressDetails() {
    const orderData = addDeliveryInfoInCart(cart, getStringifyAddress());
    dispatch(
      setCart(orderData, () => {
        dispatch(placeOrder({ ...orderData }, router));
      })
    );
  }

  const onCartPlaceOrderBtnHandle = () => {
    setOrderButtonClickCount((count) => count + 1);

    if (canDisablePlaceOrder()) {
      return;
    }

    if (!cart?.store_id) {
      cart.store_id = storeInfo?.store_id;
    }

    userData?.isLogin
      ? dispatch(validateCart(cart, onCartValidationSuccess))
      : toggleAuthModal();
  };

  const onCartValidationSuccess = (validateCartData) => {
    if (!validateCartData?.message_list?.length) {
      const type = getOrderTypeTextForCart(cart.order_type);

      if (
        type === CART_ORDER_TYPE_TEXT.DELIVERY &&
        userData?.isLogin &&
        !cart.delivery_info?.address1
      ) {
        setShowAddressError(true);
        return;
      }

      if (
        type === CART_ORDER_TYPE_TEXT.PICKUP &&
        storeInfo?.services?.pickup_flag === PICKUP_UNAVILABLE_FLAG
      ) {
        toastNotifyError('We do not accept Pickup orders currently');
        return;
      }
      if (
        type === CART_ORDER_TYPE_TEXT.DELIVERY &&
        storeInfo?.services?.delivery_flag === 1 &&
        storeInfo?.services?.min_order_value > 0 &&
        storeInfo?.services?.min_order_value > cart.items_total
      ) {
        toastNotifyError(
          `Shop for ₹${
            storeInfo?.services?.min_order_value - cart.items_total
          } more for home delivery`
        );
        return;
      }

      if (
        storeInfo?.services?.payment_method === 0 &&
        localStorage?.getItem('auth_token') &&
        cart.order_type === ORDER_TYPE.PICKUP
      ) {
        setOrderConfirmLoaderToggle(!orderConfirmLoaderToggle);
      } else submitCart();
    }
  };

  const showValidationError = () => {
    userData?.isLogin
      ? dispatch(setCartValidationErrorMsgModalVisibility(true))
      : toggleAuthModal();
  };

  const contextValue = {
    allItems,
    userData,
    storeInfo,
    showAuthModal: toggleAuthModal,
    isLogin: !!userData?.isLogin,
    showAddressError,
    setShowAddressError,
    deletionAlertState,
    setDeletionAlertState,
    onCartPlaceOrderBtnHandle,
  };

  const isEmptyCart = !allItems?.length && isComponentMounted;

  return (
    <>
      <CartContext.Provider value={contextValue}>
        <CartPage key="cartPagePremium">
          <PageHeader isEmptyCart={isEmptyCart} />
          <ModifiedCartNotificationBar
            cartValidationData={cartValidationData}
            showValidationError={showValidationError}
          />
          <CartBody>
            {isEmptyCart ? (
              <EmptyCartView />
            ) : (
              <>
                {IS_MOBILE && (
                  <CartSummery
                    summeryClasses="tw-text-[16px] tw-font-semibold"
                    showDropdown={true}
                    showOrderSummary={showSummeryMobile}
                    handleOrderSummary={() => setShowSummeryMobile(!showSummeryMobile)}
                    referenceToCheckScrollHeight={null}
                    allItems={allItems}
                  />
                )}
                <CheckoutAdditionalInfo allItems={allItems} />
                <CartItemsAndCheckout allItems={allItems} />
              </>
            )}
          </CartBody>
          {state.showAuthModel && (
            <AuthModal
              toggleModal={toggleAuthModal}
              submitCart={submitCart}
              isVisible={state.showAuthModel}
              redirection={false}
              isPhoneNumber={state.isPhoneNumber}
              handleOnCloseButton={handleCloseButton}
              isFromCart={true}
            />
          )}
          {orderConfirmLoaderToggle && (
            <OrderConfirmLoader
              orderType={cart.order_type}
              isVisible={orderConfirmLoaderToggle}
              toggleModal={() => {
                if (cart.order_type)
                  setOrderConfirmLoaderToggle(!orderConfirmLoaderToggle);
              }}
              closeModal={() => setOrderConfirmLoaderToggle(!orderConfirmLoaderToggle)}
              submitAction={submitCart}
              deliveryType={cart.order_type}
            />
          )}
          <CartValidationErrorMsgModal />
          <NotAcceptingOrderPopUp />
          {deviceWidth > 990 ? <DesktopPaymentModal /> : <PaymentModal />}
          {personalizedProduct?.personalizationPopUp && <ProductCustomizationPopUp />}
          {deletionAlertState.show && <CartItemDeletionAlert />}
          <QuntityUnavailableAlert />
        </CartPage>
      </CartContext.Provider>
    </>
  );
};

export default PreCartPage;
