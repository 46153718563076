import { useSSRSelector } from 'src/redux';
import { getThemeColor } from 'src/utils/getThemeColor';
import { ORDER_TYPE } from 'src/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { CartDeliveryTooltip } from '../../../../cart.styles';
import { setCart } from 'src/redux/actions';
import { useContext, useEffect, useState } from 'react';
import { CheckedIcon, PickupIcon, UncheckedIcon } from 'src/containers/Cart/SVGs';
import { CartContext } from 'src/context/CartContext';
import { ShippingTruck } from 'src/assets/svgExports/ShippingTruck';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const OrderTypeSelector = () => {
  const { showAuthModal = null } = useContext(CartContext);
  const dispatch = useDispatch();
  const themeColor = getThemeColor() || '#000000';

  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));

  const { cart, userData } = useSelector((state: RootState) => ({
    cart: state.cartReducer,
    userData: state.userReducer,
  }));

  const [toastState, setToastState] = useState({
    show: false,
    text: '',
  });

  useEffect(() => {
    if (storeInfo?.services?.delivery_flag) {
      if (
        storeInfo?.services?.min_order_value > 0 &&
        storeInfo?.services?.min_order_value > cart.items_total
      ) {
        if (storeInfo?.services?.pickup_flag === 0) {
          onCartDeliveryInfoHandle(
            `Shop for ₹${
              storeInfo?.services?.min_order_value - cart.items_total
            } more for home delivery`
          );
        } else {
          if (cart.order_type === ORDER_TYPE.DELIVERY) {
            togglePickupDeliverySwitch(ORDER_TYPE.PICKUP);
          }
        }
      }
    }
  }, []);

  const onCartDeliveryInfoHandle = (text) => {
    setToastState({
      show: true,
      text: text,
    });
    setTimeout(() => {
      setToastState({ show: false, text: '' });
    }, 3000);
  };

  /**
   * Helper function to return a
   * delivery charge
   * @param {string} orderTypeCheck
   * @return {number}
   */

  const togglePickupDeliverySwitch = (value) => {
    if (value === cart.order_type) {
      return;
    }
    if (value === ORDER_TYPE.PICKUP && storeInfo?.services.pickup_flag === 0) {
      onCartDeliveryInfoHandle('We are not accepting pickup orders right now');
      return;
    }

    if (storeInfo?.services?.delivery_flag) {
      if (
        storeInfo?.services?.min_order_value > 0 &&
        storeInfo?.services?.min_order_value > cart.pay_amount &&
        value === ORDER_TYPE.DELIVERY
      ) {
        onCartDeliveryInfoHandle(
          `Shop for ₹${
            storeInfo?.services?.min_order_value - cart.pay_amount
          } more for home delivery`
        );
      } else {
        dispatch(
          setCart({
            ...cart,
            order_type: value,
            store_id: storeInfo.store_id,
            user_phone: cart.phone,
            cart_id: cart.cart_id,
          })
        );
      }
    } else {
      onCartDeliveryInfoHandle('Store is currently not delivering to your area');
    }
  };

  const switchesData = [
    {
      icon: <ShippingTruck />,
      heading: 'Deliver this to an address',
      desc: 'Get estimated delivery time after you add the address',
      value: ORDER_TYPE.DELIVERY,
      isActive: cart.order_type === ORDER_TYPE.DELIVERY,
    },
    {
      icon: <PickupIcon />,
      heading: 'Pickup from store',
      desc: 'Check with store if pickup is available today',
      value: ORDER_TYPE.PICKUP,
      isActive: cart.order_type === ORDER_TYPE.PICKUP,
    },
  ];

  return !!storeInfo?.services.pickup_flag && !!storeInfo?.services?.delivery_flag ? (
    <div className="tw-relative tw-border-t tw-border-solid tw-border-[#E2E2E2] md:tw-border-none">
      <div
        className="tw-mx-[16px] tw-mb-[8px] tw-mt-[16px] tw-flex tw-flex-col tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid md:tw-mx-0 md:tw-mb-0 md:tw-flex-row"
        style={{
          borderColor: themeColor,
        }}
      >
        {switchesData.map((data, index) => {
          return (
            <div
              key={index}
              className={`tw-flex tw-flex-1 tw-cursor-pointer tw-flex-row-reverse tw-justify-between tw-gap-[16px] tw-border-b tw-border-solid tw-border-[#E2E2E2] tw-px-[20px] tw-py-[20px] md:tw-flex-row md:tw-justify-start md:tw-border-r md:tw-py-[24px] ${data.isActive ? 'tw-bg-[#F7F7F7]' : ''}`}
              onClick={() => {
                !userData?.isLogin
                  ? showAuthModal?.()
                  : togglePickupDeliverySwitch(data.value);
              }}
            >
              <span style={{ color: themeColor }}>
                {data.isActive ? <CheckedIcon color={themeColor} /> : <UncheckedIcon />}
              </span>
              <div className="tw-flex tw-h-fit tw-items-center tw-gap-[12px]">
                {!IS_DESKTOP && data.icon}
                <div className="tw-flex tw-flex-col tw-gap-[4px]">
                  <p
                    className={`tw-m-0 tw-text-sm ${data.isActive ? 'tw-font-semibold' : 'tw-font-medium'}`}
                  >
                    {data.heading}
                  </p>
                  <p className="tw-m-0 tw-hidden tw-text-xs tw-text-[#666666] md:tw-block">
                    {data.desc}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {toastState.show && <CartDeliveryTooltip>{toastState.text}</CartDeliveryTooltip>}
    </div>
  ) : (
    <>{toastState.show && <CartDeliveryTooltip>{toastState.text}</CartDeliveryTooltip>}</>
  );
};

export default OrderTypeSelector;
