import { Button } from '@/components/DesignSystem/AtomicComponents';
import { roundnessCalculator } from '@/components/DesignSystem/AtomicComponents/util';
import { deviceWidth } from '@/utils/deviceWidth';
import styled from 'styled-components';

const OuterPadding = styled.section`
  position: relative;
`;

export const CartPage = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  @media screen and (max-width: 766px) {
    & > .cart-update-notifier {
      top: 66px;
      position: relative;
    }
  }
`;

export const CartHeader = styled.section`
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  left: 0;
  height: 66px;
  z-index: 10;
  background: #ffffff;
  transition-property: box-shadow;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  border-bottom: 1px solid #e9e9e9;
  @media screen and (max-width: 766px) {
    padding: 10px 20px;
  }
`;

export const CartBody = styled.article`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 766px) {
    overflow: scroll;
    padding-top: 66px;
    flex-direction: column;
  }
`;

export const CartHeaderBack = styled.span`
  flex: 0 0 auto;
  display: flex;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
`;

export const CartHeaderData = styled.article`
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  padding-left: 10px;
  position: relative;
`;

export const CardHeaderInner = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: linear;
`;

export const CartHeaderText = styled.h3`
  font-size: 16px;
  margin: 0;
  padding-right: 10px;
  color: #000000;
`;

export const CartInfo = styled(OuterPadding)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const CartOption = styled.article`
  display: flex;
  justify-items: space-between;
  flex: 1;
  box-sizing: border-box;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
  @media screen and (max-width: 766px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Text = styled.span`
  display: flex;
  position: relative;
  color: #000000;
  font-size: 28px;
  font-weight: 500;
  margin-right: 10px;
`;

export const MidContent = styled(OuterPadding)`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(112, 112, 112, 0.5);
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  color: ${(props) => props.fontColor || '#6E03FF'};
  cursor: ${(props) => (props.cursor ? props.cursor : 'default')};

  @media screen and (min-width: 767px) {
    font-size: 16px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
  }
`;

export const ItemContainer = styled(OuterPadding)``;

export const ItemPrecursor = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  @media screen and (min-width: 767px) {
    display: none;
  }
`;

export const ItemPrecursorText = styled.span`
  color: #606060;
  font-size: 12px;
`;

export const ItemList = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BottomContainer = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  padding: 20px 32px;
  background: #fafafa;
  margin-top: auto;
  ${(props) =>
    props.showScrollDownShadow && `box-shadow: rgb(0 0 0 / 20%) 0px -2px 10px -5px`};
  ${(props) => props.storeUnserviceable && `min-height: 15vh`};

  @media screen and (max-width: 766px) {
    background: white;
    position: static;
    border: none;
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const CTAbutton = styled(Button)`
  padding: 16px 0px;
`;

export const IconContainer = styled.span`
  display: inline-block;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  & > svg {
    top: 3px;
    position: relative;
  }
`;

export const AmountContainer = styled(OuterPadding)`
  padding: 16px 0px 24px;
  border-top: 1px solid #eaeaec;
`;

export const AmountRow = styled.section`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  min-width: 26.875vw;
  ${(props) => props.marginRight && `margin-right : ${props.marginRight}vw`};
  @media screen and (max-width: 767px) {
    min-width: 88.875vw;
  }
`;

export const TotalText = styled.span`
  font-size: 14px;
  display: flex;
  color: #000000;
  font-weight: ${(props) => props.fontWeight};
  margin-left: ${(props) => props.marginRight || 0}px;

  @media screen and (max-width: 766px) {
    font-size: 14px;
  }
`;

export const NoItemInCart = styled.section`
  display: flex;
  height: auto;
  width: 100%;
  padding: 2rem 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
`;

export const NoItemInCartSection = styled.div`
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoItemInCartText = styled.span`
  text-align: center;
  width: 90%;

  @media screen and (min-width: 767px) {
    font-size: 20px;
    padding: 40px 0;
    margin: 0 auto;
  }

  @media screen and (max-width: 766px) {
    padding: 30px 0;
    margin: 0 auto;
    font-size: 12px;
  }
`;

export const MidContentInner = styled.section`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  width: 100%;
`;

export const OrderInstructionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const OrderInstructionTitle = styled.p`
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  justify-content: space-between;
  font-weight: 600;
  color: gray;

  @media screen and (min-width: 767px) {
    font-size: 15px;
  }

  @media screen and (max-width: 766px) {
    font-size: 13px;
  }
`;

export const OrderInstructionEdit = styled.span`
  position: relative;
  display: flex;
  width: 14px;
  height: 14px;
  margin-left: 20px;
  font-size: 12px;
  cursor: pointer;
  border-radius: ${(props) => roundnessCalculator(props.roundness)};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  width: auto;
  height: auto;
  font-weight: 600;
  padding: 3px 5px;
  background-color: transparent;
`;

export const OrderInstructionDescription = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  color: #000;
  font-size: 15px;
`;

export const SVGBox = styled.span`
  display: inline-block;
  position: relative;
  height: 12px;
  width: 12px;
  margin-left: 7px;
`;

export const CartDeliveryTooltip = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #343638;
  box-shadow: 3px 5px 12px 1px #0000002f;
  z-index: 999;
  width: fit-content;
  padding: 8px;
  border-radius: 8px;
  font-size: 12px;
  color: #ebebeb;
`;

export const CartBodyRightSection = styled.article`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  width: 35%;
  background: #fafafa;
  min-width: 560px;
  @media screen and (max-width: 767px) {
    overflow: visible;
    min-width: 100%;
  }
`;

export const ItemHeading = styled.div`
  padding: ${(props) => (props.showDropdown ? '28px 0px' : '24px 0px')};
  background: ${(props) => (props.showDropdown ? '#fafafa' : 'white')};
  display: flex;
  justify-content: space-between;
  border-bottom: ${(props) => (!props.showSummery ? '1px solid #eaeaec' : 'none')};
`;

export const GrandTotal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GrandTotalText = styled.div`
  display: flex;
`;

export const GrandTotalValue = styled.div`
  display: flex;
`;

export const ItemAmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 30px 0px;
  background: #fafafa;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for IE, Edge and Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 767px) {
    overflow: visible;
    height: auto;
    padding: 0 16px;
    background: ${(props) => (!props.showDropdown ? 'white' : '#fafafa')}
      ${(props) =>
        !props.showOrderSummary &&
        `background : white ; visibility : hidden ; height : 50vh ; overflow: hidden;`};
  }
`;
export const OrderSummary = styled.div`
  display: flex;
`;

export const DropDownArrowSummary = styled.div`
  display: flex;
  margin-right: 16px;
  ${(props) =>
    props.showOrderSummary
      ? `transform : rotateZ(180deg);`
      : `transform: rotateZ(360deg);`}
`;

export const OrderSummaryText = styled.div`
  margin-left: ${deviceWidth < 767 ? '16px' : '0px'};
  margin-top: 2px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const DropDownArrowContainer = styled.div`
  display: flex;
  margin-left: 8px;
  margin-top: 6px;
  cursor: pointer;
  ${(props) =>
    props.showDeliveryChargeList && `transform : rotate(180deg); margin-bottom : 4px`};
`;

export const DeliveryChargeDropDownContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MobileGrandTotal = styled.div`
  display: flex;
  margin-right: 10px;
  margin-top: 4px;
`;

export const TotalDropDownArrowContainer = styled.div`
  display: flex;
`;

export const TotalItemsInCart = styled.div`
  display: flex;
  margin-top: 8px;
  color: black;
  opacity: 0.49;
`;

export const GstExcludesContainer = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const GstIncludesContainer = styled.article`
  display: flex;
  margin-left: 6px;
  font-size: 14px;
  color: #797a7c;
  font-weight: 400;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    align-items: center;
  }
`;
